<template>
  <div class="dashboard1 p-5" id="dementie-information">
      <div class="row">
        <div class="col-sm-12 down">
          <div>
            <h1 class="intro-section-title">Diagnose fase</h1>
            <div class="row">
              <p>
                Op deze pagina vindt u informatie over mensen met dementie in de Diagnose fase: de periode rondom de eerste vaststelling van de diagnose dementie. Tijdens deze fase verandert er vaak veel voor mensen met dementie en hun naasten, en zorgprofessionals zoals huisarts en casemanager worden betrokken bij het verlenen van de juiste zorg en ondersteuning. Hier kunt u inzichten in de volgende thema's vinden over de diagnose fase:
              </p>
            </div>

            <div class="row align-items-center justify-content-around link-group">
              <div class="col-sm">
                <p a class="levenmet-text"><a class="levenmet-link" href="/Diagnose/9">Wmo/Wlz</a><br></p>
              </div>
              <div class="col-sm">
                <p a class="levenmet-text"><a class="levenmet-link" href="/Diagnose/2">Wonen</a><br></p>
              </div>
              <div class="col-sm">
                <p a class="levenmet-text"><a class="levenmet-link" href="/Diagnose/10">Huisarts</a><br></p>
              </div>
              <div class="col-sm">
                <p a class="levenmet-text"><a class="levenmet-link" href="/Diagnose/7">Overig</a><br></p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- The hr used to divide intro and footer in the below -->
      <!-- <hr class="rounded"> -->
      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta';

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Diagnose fase',
  },
  components: { Footer  },
  data() {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
    }
  },
}

</script>

<style scoped>

ul {
  list-style-type: none;
}

hr.rounded {
  border-top: 8px solid var(--color-12);
  border-radius: 5px;
}


.intro-section-title{
font-weight: bold;
margin-bottom: 2rem;
}

.intro-section-text{
  text-align: justify;
}


p,
.levenmet-link{
  color: var(--color-12);
}

.levenmet-link{
  font-weight: bold;
  display: block;
}

.p{
  margin: 30px 0 30px 0;
  font-weight: thin;
}

.levenmet-link:hover,
.levenmet-link:focus{
  outline: none;
  box-shadow: none;
  color: var(--color-11);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
}

.levenmet-text{
  text-align: center;
}

.link-group{
  margin: 3rem;
}

.second-link-group{
  margin-top: 1rem;
}
</style>

